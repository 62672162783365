<template>
  <div class="hcIsBroughtOut">
    <div class="hcIsBroughtOut__title">
      <div class="leftTitle">
        <span>招聘配额</span>
        <span> / 接受</span>
      </div>
      <div class="bottomButton">
        <el-button @click="goBack">取消 </el-button>
        <el-button type="primary" @click="zhuanru">转入</el-button>
      </div>
    </div>
    <div class="hcIsBroughtOut__content">
      <descriptions :descriptionData="descriptionData" />
      <PGTitle title="附件信息">
        <pgUploadFile :limit="3" :id="id" :uploadListCall="uploadListCall" />
      </PGTitle>
      <PGTitle title="转入信息">
        <FormDesigner :formScale="formScale" ref="FormDesignerRef" />
      </PGTitle>
    </div>
  </div>
</template>

<script>
import FormDesigner from '@/components/FormDesigner/FormDesigner'
import pgUploadFile from '@/components/pg-uploadFile/pg-uploadFile'
import descriptions from '@/components/descriptions/descriptions'
import PGTitle from '@/components/PG-title/PG-title'
import {
  getChildFunction,
  getCodeValue,
  getLineManager,
  getUserInfo,
  saveHc
} from '@/api/quto'
import { mergeDesDataOfPort } from '@/utils/util'
import {
  getAssigmentProjectLevel2,
  getDescriptionLevel3,
  getLegalEntity
} from '@/api/personnelRequisition'
import { getEntityHfmCode } from '@/api/intern'

export default {
  name: 'hcIsBroughtOut',
  data() {
    return {
      formScale: [
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'HFM Code',
                prop: 'hfmCode',
                type: 'select',
                options: [],
                rules: '',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                change: async (val) => {
                  // let isRight = await this.$refs.FormDesignerRef.checkOrganization();
                  // if(!isRight) return;
                  getLegalEntity({ hfmCode: val }).then((LegalEntity) => {
                    if (LegalEntity.code && LegalEntity.code === 200) {
                      let data = {
                        legalEntity: LegalEntity.data
                      }
                      this.$refs.FormDesignerRef.setOptions(
                        data,
                        this.formScale
                      )
                      this.$refs.FormDesignerRef.setValSign(
                        'legalEntity',
                        '',
                        this.formScale
                      )
                    }
                  })
                },
                onClick: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'legalEntity',
                rules: '',
                label: 'Legal Entity',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: () => {
                  // this.$refs.FormDesignerRef.checkOrganization();
                },
                onClick: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'client',
                rules: '',
                label: 'Client',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'businessUnit',
                rules: '',
                disabled: true,
                label: 'Business Unit',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Department',
                prop: 'department',
                type: 'select',
                options: [],
                rules: '',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                change: () => {},
                onClick: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'englishTitle',
                rules: '',
                label: 'English Title',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'cnTitle',
                rules: '',
                label: 'CN Title',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'jobGrading',
                rules: '',
                label: 'Job Grading',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: () => {},
                onClick: () => {}
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Work Schedules',
                prop: 'workSchedules',
                type: 'select',
                options: [],
                rules: '',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                change: () => {},
                onClick: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'parentFunction',
                rules: '',
                label: 'Parent Function',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: (val) => {
                  getChildFunction({ parentFunction: val }).then((res) => {
                    if (res && res.code && res.code === 200) {
                      if (res.data) {
                        let data = {
                          childFunction: res.data
                        }
                        this.$refs.FormDesignerRef.setOptions(
                          data,
                          this.formScale
                        )
                      }
                    }
                  })
                }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'childFunction',
                rules: '',
                label: 'Child Function',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: () => {},
                onClick: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'laborCode',
                rules: '',
                label: 'Labor Code',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: () => {},
                onClick: () => {}
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Location',
                prop: 'location',
                type: 'select',
                options: [],
                rules: '',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                change: () => {
                  // this.$refs.FormDesignerRef.checkOrganization();
                },
                onClick: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'office',
                rules: '',
                label: 'office',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: () => {},
                onClick: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lineManager',
                rules: '',
                label: 'Line Manager',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: () => {},
                onClick: (val) => {
                  this.$refs.FormDesignerRef.setValSign(
                    'lionIdOfLineManager',
                    val.lionIdOfLineManager,
                    this.formScale
                  )
                }
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lionIdOfLineManager',
                rules: '',
                label: 'Lion ID of Line Manager',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            // {
            //   span: 6,
            //   id: Math.random(),
            //   formData: {
            //     id: Math.random(),
            //     label: 'Name of Vega LM',
            //     prop: 'nameOfVegaLm',
            //     type: 'select',
            //     options: [],
            //     rules: '',
            //     value: '',
            //     clearable: true,
            //     filterable: true,
            //     placeholder: '请选择',
            //     change: () => {},
            //     onClick: (val) => {
            //       this.$refs.FormDesignerRef.setValSign(
            //         'lionIdOfLmVega',
            //         val.lionIdOfLmVega,
            //         this.formScale
            //       )
            //     }
            //   }
            // },
            // {
            //   span: 6,
            //   id: Math.random(),
            //   formData: {
            //     prop: 'lionIdOfLmVega',
            //     rules: '',
            //     label: 'Lion ID of LM Vega',
            //     id: Math.random(),
            //     type: 'input',
            //     value: ''
            //   }
            // },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'newContract',
                rules: '',
                label: 'New Contract',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: () => {},
                onClick: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'newEmploymentContractStartDate',
                rules: '',
                label: 'New Employment Contract Start Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Assigment/project (Level2)',
                prop: 'assigmentProjectLevel2',
                type: 'select',
                options: [],
                rules: '',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                change: () => {
                  // this.$refs.FormDesignerRef.checkOrganization();
                },
                onClick: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'descriptionLevel3',
                rules: '',
                label: 'Description (Level3)',
                id: Math.random(),
                options: [],
                clearable: true,
                filterable: true,
                type: 'select',
                value: '',
                change: () => {},
                onClick: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'newEmploymentContractDueDate',
                rules: '',
                label: 'New Employment Contract Due Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'effectiveDate',
                rules: '',
                label: 'Effective Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            }
          ]
        }
      ],
      descriptionData: [
        {
          title: '基础信息',
          column: 4,
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: ''
            },
            {
              label: 'Chinese Name',
              key: 'chineseName',
              value: ''
            },
            {
              label: 'Lion ID',
              key: 'lionId',
              value: ''
            },
            {
              label: 'Name of Bank',
              key: 'nameOfBank',
              value: ''
            },
            {
              label: 'Bank Account No.',
              key: 'bankAccountNo',
              value: ''
            }
          ]
        }
      ],
      uploadListCall: [],
      id: ''
    }
  },
  created() {
    let id = this.$route.query.id
    this.id = id
    this.getOptions()
    this.queryApi(id)
  },
  methods: {
    goBack() {
      this.$router.push({
        path: '/employmentApplication/quotaManagement'
      })
    },
    async zhuanru() {
      const data = await this.$refs.FormDesignerRef.getValue()
      const returnData = {
        id: this.id,
        ...data
      }
      saveHc(returnData).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('转入成功')
          this.goBack()
        }
      })
    },
    getOptions() {
      getCodeValue().then((res) => {
        if (res && res.code === 200) {
          this.$refs.FormDesignerRef.setOptions(res.data, this.formScale)
        }
      })
      getLineManager().then((res) => {
        if (res && res.code === 200) {
          this.$refs.FormDesignerRef.setOptions(res.data, this.formScale)
        }
      })
      getAssigmentProjectLevel2().then((res) => {
        if (res && res.code && res.code === 200) {
          if (res.data) {
            let data = {
              assigmentProjectLevel2: res.data
            }
            this.$refs.FormDesignerRef.setOptions(data, this.formScale)
          }
        }
      })
      getDescriptionLevel3().then((res) => {
        if (res && res.code && res.code === 200) {
          if (res.data) {
            let data = {
              descriptionLevel3: res.data
            }
            this.$refs.FormDesignerRef.setOptions(data, this.formScale)
          }
        }
      })
      getChildFunction().then((res) => {
        if (res && res.code && res.code === 200) {
          if (res.data) {
            let data = {
              childFunction: res.data
            }
            this.$refs.FormDesignerRef.setOptions(data, this.formScale)
          }
        }
      })
      getEntityHfmCode().then((HfmCode) => {
        if (HfmCode && HfmCode.code === 200) {
          let data = {
            hfmCode: res.data
          }
          this.$refs.FormDesignerRef.setOptions(data, this.formScale)
        }
      })
      getLegalEntity().then((LegalEntity) => {
        if (LegalEntity.code && LegalEntity.code === 200) {
          let data = {
            legalEntity: LegalEntity.data
          }
          this.$refs.FormDesignerRef.setOptions(data, this.formScale)
        }
      })
    },
    queryApi(id) {
      getUserInfo({ id }).then((res) => {
        if (res && res.code === 200) {
          mergeDesDataOfPort(this.descriptionData, res.data)
          this.uploadListCall = res.data.files
          this.$refs.FormDesignerRef.setValue(res.data)
        }
      })
    }
  },
  components: {
    FormDesigner,
    descriptions,
    pgUploadFile,
    PGTitle
  }
}
</script>
<style lang="scss" scoped>
.hcIsBroughtOut {
  padding: 10px;
  background: white;
  overflow-x: hidden;
  overflow-y: scroll;
  &__content {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 150px);
  }
  .hcIsBroughtOut__title {
    height: 50px;
    border-bottom: 1px dashed rgb(153, 153, 153);
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .leftTitle {
      font-size: 18px;
      span {
        &:first-child {
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #868686;
        }
        &:last-child {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #303133;
        }
      }
    }
  }
  .bottomButton {
    //margin-bottom: 50px;
    text-align: center;
  }
}
</style>
